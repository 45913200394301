import React from 'react';
import Layout from '../components/layout';
import { Backdrop, BookATable, Button } from '../styledComponent/sc';
import bookImg from '../images/book-img.jpeg';
import gaodrinks from '../images/gaodrinks.jpeg';
import gao1 from '../images/food-cut1.png';
import gao2 from '../images/food-cut2.png';
import gao3 from '../images/food-cut3.png';
import { FaHandPointRight } from '@react-icons/all-files/fa/FaHandPointRight';
// import { GiMonsteraLeaf } from "@react-icons/all-files/gi/GiMonsteraLeaf "
import Highlight from '../components/highlight';
import SEO from '../components/seo';
export default function book() {
  return (
    <Layout>
      <SEO title='About 🍎' />
      <div className='image-bg'>
        <img src={gao3} alt='A beautiful Vegan' />
      </div>
      <div className='book-title'>
        <h1>Gạo's Restaurant</h1>
        <h2>
          WELCOME TO <span>Vegan </span>
        </h2>
      </div>
      {/* <Backdrop style={{ paddingTop: "200px" }}> */}
      <Backdrop>
        <div className='cta-book cta-info'>
          <p> Anschrift: </p> <br />
          <h3>Wesselheideweg 101, 53123 Bonn</h3>
          {/* <Button text="MAKE A RESERVATION" color="black" /> */}
        </div>
        <BookATable className='book-page flex-box'>
          <img src={bookImg} alt='' width='500px' height='500px' />
          <div className='book-card'>
            <h1>Online Reservation</h1>
            <form action=''>
              <label htmlFor='date'>
                <h3>Date of visit</h3>

                <input type='date' name='date' id='date' />
              </label>
              <label htmlFor='time'>
                <h3>Visit time</h3>
                <input type='time' name='time' id='time' />
              </label>
              <label htmlFor='text'>
                <h3>Phone number</h3>{' '}
                <input
                  type='text'
                  name='text'
                  id='number'
                  placeholder='+49 1234567890'
                />{' '}
              </label>
              <label htmlFor='email'>
                <h3>Email address</h3>{' '}
                <input
                  type='email'
                  name='email'
                  id='email'
                  placeholder='visiter@email.com'
                />{' '}
              </label>
              <label htmlFor='number'>
                <h3>Number of visiters</h3>
                <input
                  type='number'
                  name='number'
                  id='number'
                  placeholder='1'
                />
              </label>
              <label htmlFor='checkbox'>
                <h3>High chair(children under 6 years old)</h3>{' '}
                <input type='checkbox' name='checkbox' id='checkbox' />{' '}
              </label>
              <button type='submit'>
                <FaHandPointRight className='icon' />
                RESERVE NOW
              </button>
              <h1>
                *Hotline : <span>0341 30855296</span>
              </h1>
            </form>
          </div>
        </BookATable>
        {/* <div className="image-bg">
          <img src={gao1} alt="A beautiful Vegan" />
          <img src={gao2} alt="A beautiful Vegan" />
          <img src={gao3} alt="A beautiful Vegan" />
        </div> */}
        <div className='cta-book cta-info'>
          <p>
            {' '}
            Gạo restaurant’s menu consists entirely of fresh, seasonal
            plant-based ingredients, which are 100% organic, vegan and
            sustainably sourced from local farm cooperatives and partnerships in
            Italy and Germany. It’s downright delicious.{' '}
          </p>{' '}
          <br />
          {/* <Button text="MAKE A RESERVATION" color="black" /> */}
        </div>
      </Backdrop>
      <Highlight
        imgUrl={gaodrinks}
        text='Discover our magical drinks'
      ></Highlight>
    </Layout>
  );
}
